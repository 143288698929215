import React from "react"
import Layout from "../../../components/layout"
import SystemOperation from "./systemOperation"
import Pressurization from "./pressurization"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Helmet } from "react-helmet"
import productsPageStyles from "../../../styles/productsPage.module.scss"
import data from "../../../data/fps955"
import NACAB from "../../resources/NA_CAB-Pressure-Cab-Protection-Levels.pdf"
import CRFR from "../../resources/CAF 2 Recommended Flow Rate Test Procedure  R20134.pdf"
import FII from "../../resources/FPS Installation Instructions.pdf"
import PSFTP from "../../resources/CAF 3 Particulate System  Filter Test Procedure R20134.pdf"
import VTC from "../../resources/CAF 6 Vapor Test Procedure R20204.pdf"

export default function () {
  const { fps955 } = useStaticQuery(
    graphql`
      query fps955 {
        fps955: file(relativePath: { eq: "pressurizer.png" }) {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
            fixed(width: 450) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    `
  )
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>FPS 955 | Clean Air Filter</title>
      </Helmet>
      <section>
        <h1>
          <span className="tail">
            <span className="head">{data.content.section1.h1.head}</span>{" "}
            {data.content.section1.h1.tail}
          </span>
        </h1>
        <div className="float-right">
          <Img fluid={fps955.childImageSharp.fluid} />
        </div>
        <article className="w-60">
          <p>{data.content.section1.p1}</p>
          <h2>{data.content.section1.section9952.h2}</h2>
          <ul>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section1.section9952.ul.li1,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section1.section9952.ul.li2,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section1.section9952.ul.li3,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section1.section9952.ul.li4,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section1.section9952.ul.li5,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section1.section9952.ul.li6,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section1.section9952.ul.li7,
              }}
            ></li>
          </ul>
          <h2>{data.content.section1.section9951.h2}</h2>
          <ul>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section1.section9951.ul.li1,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section1.section9951.ul.li2,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section1.section9951.ul.li3,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section1.section9951.ul.li4,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section1.section9951.ul.li5,
              }}
            ></li>
            <li
              dangerouslySetInnerHTML={{
                __html: data.content.section1.section9951.ul.li6,
              }}
            ></li>
          </ul>
          <p>{data.content.section1.p2}</p>
        </article>
      </section>

      <hr></hr>
      <div
        className="w-60"
        dangerouslySetInnerHTML={{ __html: data.content.systemOperation }}
      ></div>

      {/* <p><Link className="primary red" to="/products/air-filters" >View CAF Recommended Flow Rate</Link></p> */}

      <p>
        <a
          className="download-link"
          href={FII}
          target="_blank"
          rel="noreferrer"
        >
          {data.content.a1}
        </a>
      </p>

      <div
        className="w-60"
        dangerouslySetInnerHTML={{ __html: data.content.pressurization }}
      ></div>

      <p>
        <a
          className="download-link"
          href={CRFR}
          target="_blank"
          rel="noreferrer"
        >
          {data.content.a2}
        </a>
      </p>
      <p>
        <a
          className="download-link"
          href={NACAB}
          target="_blank"
          rel="noreferrer"
        >
          {data.content.a3}
        </a>
      </p>
      <p>
        <a
          className="download-link"
          href={PSFTP}
          target="_blank"
          rel="noreferrer"
        >
          {data.content.a4}
        </a>
      </p>
      <p>
        <a
          className="download-link"
          href={VTC}
          target="_blank"
          rel="noreferrer"
        >
          {data.content.a5}
        </a>
      </p>
      <p>
        <Link
          className="primary red"
          to="/products/filtration-protection-systems/fps-filters"
        >
          {data.content.a6}
        </Link>
      </p>
    </Layout>
  )
}
